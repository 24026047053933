import { Side } from "../models/Common";
import { Surface, surfaceToStr } from "../models/Customization";
import { IRoundedRectShape, getFullRadius } from "../models/Rectangle";
import { ISolution } from "../models/Solution";
import { BaseComponent, IComponentInfo, IComponentLabels } from "./Base";

export class Glass extends BaseComponent {
    public constructor(id: number, solution: ISolution) {
        const glass = solution.glass;
        const customization = solution.customization;
        const thickness = glass.isDouble ? `${glass.thickness}+${glass.thickness}` : glass.thickness;

        let description = `${glass.isDouble ? 'double' : 'front'} glass ${thickness} mm`;
        if (customization)
            description += ` ${surfaceToStr(customization?.surface)}`;
        if (customization?.surface === Surface.AntiGlare)
            description += ` (${customization.antiGlareType})`;
        if (customization?.lamination)
            description += ' laminated';
        if (customization?.tempering)
            description += ' tempered';
        if (customization?.overheatingProtection)
            description += ' with over-heating protection'

        super({
            id,
            color: "#000",
            description,
        } as IComponentInfo,
        {
            width: solution.glass.width,
            height: solution.glass.height,
            radius: getFullRadius(solution.customization?.corners),
        } as IRoundedRectShape,
        {
            dimensions: {
                sides: [ Side.Top, Side.Bottom, Side.Left, Side.Right ],
                diff: false,
            },
            description: false,
        } as IComponentLabels);
    }
}
import { useContext, useCallback, useMemo } from "react";
import * as d3 from "d3";
import { FontContext, IArea } from "./Common";
import { ISolution } from "../models/Solution";
import { ReactComponent as FortesLogo } from "../Forteslogo_REG_black.svg";
import { floatToStr } from "../models/Common";
import { Surface, surfaceToStr } from "../models/Customization";
import React from "react";


export interface IInfoTableProps {
    area: IArea,
    solution: ISolution,
}
  
export function InfoTable({ area, solution }: IInfoTableProps) {
    const { size: fontSize } = useContext(FontContext);
    const paddingX = 10;

    const date = new Date(Date.now());

    const rows = 7;
    const rowHeight = area.height / rows;
    const logoPadding = 1.2 * fontSize;

    const cellX = useMemo(() => [ area.offsetX, area.offsetX + 0.3 * area.width, area.offsetX + 0.55 * area.width ], [area]);
    const cellY = useMemo(() => {
        const c = [ area.offsetY ];
        for (let i = 1; i < rows; ++i)
            c.push(c[i - 1] + rowHeight);
        return c;
    }, [area, rowHeight]);

    const lastX = area.offsetX + area.width;
    const lastY = area.offsetY + area.height;

    const product = solution.product;
    const sensor = solution.sensor;
    const customization = solution.customization;
    const customer = solution.customer;
    const status = solution.status;
    
    const diagonal = floatToStr(sensor.passiveArea.diagonal);
    
    let titleStr = ""
    if(product.title === "") {
        titleStr = `${product.type} with ${diagonal}inch ${sensor.isCustom ? 'custom' : 'standard'} sensor ${sensor.tail.isCustom ? 'and custom tail' : ''}`
    }else {
        titleStr = `${product.type}, ${product.title}`
    }
    
    const cell = useCallback((label: string, text: string, col: number, row: number, colSpan = 1, rowSpan = 1) => {
        const pRow = d3.path();
        pRow.moveTo(cellX[col], cellY[row]);
        pRow.lineTo(cellX[col + colSpan] ?? lastX, cellY[row]);

        const pCol = d3.path();
        pCol.moveTo(cellX[col], cellY[row]);
        pCol.lineTo(cellX[col], cellY[row + rowSpan] ?? lastY);
            
        return <>
                <path stroke="#000" strokeWidth="1" d={pRow.toString()} />
                <path stroke="#000" strokeWidth="1" d={pCol.toString()} />
                <text x={cellX[col] + paddingX} y={cellY[row] + 0.6 * rowHeight}>
                    <tspan fontSize={fontSize}>{label} </tspan>
                    <tspan fontSize={1.2 * fontSize}>{text}</tspan>
                </text>
            </>
    }, [cellX, cellY, fontSize, lastX, lastY, paddingX, rowHeight]);

    return <g>
        <rect x={area.offsetX} y={area.offsetY} width={area.width} height={area.height} stroke="#000" fill="none" />

        <FortesLogo x={area.offsetX + logoPadding} y={area.offsetY + logoPadding}
            width={cellX[1] - cellX[0] - 2 * logoPadding} height={cellY[5] - cellY[0] - 2 * logoPadding} />

        {/*{cell("Product:", `${product.type} with ${diagonal}inch ${sensor.isCustom ? 'custom' : 'standard'} sensor ${sensor.tail.isCustom ? 'and custom tail' : ''}`, 1, 0, 2)}*/}
        {cell("Product:", titleStr, 1, 0, 2)}
        {cell("Customer:", customer.company, 1, 1, 2)}
        {cell("Quantity:", `${product.pieces}`, 1, 2)}
        {cell("Material:", !customization ? 'X' : `${surfaceToStr(customization.surface)} ${customization.surface === Surface.AntiGlare ? '('+customization.antiGlareType?.toString()+')' : ''}`, 2, 2)}
        {cell("Created by:", customer.creator, 1, 3, 2)}
        {cell("Approved by customer:", status.isApproved ? status.customerApprover : 'X', 1, 4, 2)}
        {cell("Approved by FORTES:", status.isApproved ? status.fortesApprover : 'X', 1, 5, 2)}
        {cell("Date:", `${date.toLocaleDateString('cs')}`, 0, 6)}
        {cell("", "www.fortesinteractive.com", 0, 5)}
        {cell("", "DIMENSIONS ARE IN MILIMETERS", 1, 6, 2)}
    </g>;
}
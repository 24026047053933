import { Box, Typography } from "@mui/material"
import { AdditionalNotesTypesEnum } from "../models/AdditionalText"
import { ErrorOutlineOutlined, NotificationsNoneOutlined, ReportProblemOutlined } from "@mui/icons-material"


export interface IAdditionalProps {
    text: string
    type: AdditionalNotesTypesEnum
    width: number
}

export function AdditionalText(props: IAdditionalProps) : JSX.Element {
    switch(props.type) {
      case AdditionalNotesTypesEnum.Error : return <ErrorText {...props} />
      case AdditionalNotesTypesEnum.Warning : return <WarningText {...props} />
      case AdditionalNotesTypesEnum.Note : return <NoteText {...props} />
    }  

    return (<>
      <p>
        Error
      </p>
      <p>{props.type}</p>
      <p>{props.text}</p>
    </>)
}

function ErrorText(props: IAdditionalProps) : JSX.Element {
  const width = `${props.width}px`
  return(<Box display="flex"
  sx={{
    color: "#cc0303",
    backgroundColor: "#ffe2e9",
    width: {width},
    padding:"5px 15px 5px 15px",
    borderRadius: "14px",
    margin:"10px 0 10px 0"
  }}
  >
    <ReportProblemOutlined sx={{marginRight: "10px"}}/>
    <Typography style={{ whiteSpace: 'pre-wrap' }} color="#cc0303" fontSize="100%" fontWeight="400" maxWidth="1000px">{props.text}</Typography>
  </Box>)
}

function WarningText(props: IAdditionalProps) : JSX.Element {
  const width = `${props.width}px`
  return(<Box display="flex"
    sx={{
      color: "#837600",
      backgroundColor: "#fffdc3",
      width: {width},
      padding:"5px 15px 5px 15px",
      borderRadius: "14px",
      margin:"10px 0 10px 0"
    }}
    >
      <ErrorOutlineOutlined sx={{marginRight: "10px"}}/>
      <Typography style={{ whiteSpace: 'pre-wrap' }} color="#837600" fontSize="100%" fontWeight="400" maxWidth="1000px">{props.text}</Typography>
    </Box>)
}

function NoteText(props: IAdditionalProps) : JSX.Element {
  
  const width = `${props.width}px`
  return(<Box display="flex"
    sx={{
      color: "#499025",
      backgroundColor: "#b4f3c3",
      width: {width},
      padding:"5px 15px 5px 15px",
      borderRadius: "14px",
      margin:"10px 0 10px 0"
    }}
    >
      <NotificationsNoneOutlined sx={{marginRight: "10px"}}/>
      <Typography style={{ whiteSpace: 'pre-wrap' }} color="#499025" fontSize="100%" fontWeight="400" maxWidth="1000px">{props.text}</Typography>
    </Box>)
}
import { Box, Grid, Typography } from "@mui/material";
import { SolutionField } from "./SolutionField";
import { BorderBottom, BorderLeft, BorderRight, BorderTop } from "@mui/icons-material";
import useWindowSize from "../windowSize";
import { StyledMuiFieldLabel } from "./muiFields/MuiFIeldLabel";

interface IBorderSpacingProps {
  
    label: string, 
    topSolutionFiledName: string, 
    bottomSolutionFiledName: string, 
    leftSolutionFiledName: string, 
    rightSolutionFiledName: string
  
}

export function BorderSpacing(props : IBorderSpacingProps) : JSX.Element {
  const size = useWindowSize()
  const md1 = (size.width < 1655 && size.width > 1100) ? 12 : 5
  const md2 = (size.width < 1655 && size.width > 1100) ? 12 : 7
  const direction = (size.width < 1655 && size.width > 1100) ? "column" : "row"
  return (
    <Grid container direction={direction}>
      <Grid xs={12} md={md1} >
          <StyledMuiFieldLabel label={props.label} paired={false} />
      </Grid>
      <Grid xs={12} md={md2} container justifyContent="center" >
        
          <AlignPair direction="column" firstEl={<Box sx={{marginTop: "3px"}}><BorderTop/></Box>} secondEl={<SolutionField name={props.topSolutionFiledName} />} />

          <Grid container direction="row" justifyContent="center" alignItems="center">

            <AlignPair direction="row" firstEl={<Box sx={{marginTop: "3px"}}><BorderLeft/></Box>} secondEl={<SolutionField name={props.leftSolutionFiledName} />}/>
            <AlignPair direction="row" firstEl={<SolutionField name={props.rightSolutionFiledName}/>} secondEl={<Box sx={{marginTop: "3px"}}><BorderRight/></Box>}/> 

          </Grid>
          
          <AlignPair direction="column" firstEl={<SolutionField name={props.bottomSolutionFiledName} />} secondEl={<Box sx={{marginTop: "3px"}}><BorderBottom/></Box>}/>   

      </Grid>
    </Grid>
  )
}

function AlignPair(props : {
  direction: "column" | "row",
  firstEl: JSX.Element,
  secondEl: JSX.Element
}) : JSX.Element {
  return (
    <Box sx={{
      margin:"3px"
    }}>
      <Grid container direction={props.direction} alignItems="center">
        <Grid>
          {props.firstEl}
        </Grid>
        <Grid>
          {props.secondEl}
        </Grid>
      </Grid>
    </Box>
  )
}
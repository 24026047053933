import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { setPageTitle } from "./App";
import { ISidePadding, ISideOffsets, Orientation, Side } from "./models/Common";
import { componentWantsNoRaddiRendered, getBounds, isNonZero, isRoundedRect } from "./models/Rectangle";
import { Dimensions } from "./drawing/Dimensions";
import { FontContext, ScaleContext, getDrawingInfo, getInfo, IArea } from "./drawing/Common";
import { Radius } from "./drawing/Radius";
import { Id } from "./drawing/Id";
import { Glass } from "./components/Glass";
import { GlassProfile } from "./components/GlassProfile";
import { Sensor } from "./components/Sensor";
import { ActiveArea } from "./components/ActiveArea";
import { PrintedBorder } from "./components/PrintedBorder";
import { FlexibleCable } from "./components/FlexibleCable";
import { Sticker } from "./components/Sticker";
import { SolutionContext } from "./models/Form";
import PDFDocument from "pdfkit";
import SVGtoPDF from "svg-to-pdfkit";
import blobStream from "blob-stream";
import './pdfkit-webpack/registerStaticFiles';
import { Descriptions } from "./drawing/Descriptions";
import { Box, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { InfoTable } from "./drawing/InfoTable";
import { Watermark } from "./drawing/Watermark";
import { TextBlock } from "./drawing/TextBlock";
import { Co2Sharp } from "@mui/icons-material";
import { solutionErrors, cutoutsAdditional, notesAdditional, objectNotesAdditional, objectErrorsAdditional, tailAdditional, glassSensorAdditional, tailErrorsAdditional } from "./components/Additionals";
import { fillSolutionsEmpty } from "./form/SolutionFill";
import { PrintedLogo } from "./components/PrintedLogo";
import React from "react";
import { AdditionalText } from "./drawing/AdditionalText";
import { AdditionalNotesTypesEnum, IAdditionalNotes } from "./models/AdditionalText";
import { solutionFields } from "./models/SolutionFields";

interface IDrawingProps {
    admin: boolean,
    renderEmpty: boolean
    width : number
}

// Define the methods exposed by Drawing
export interface DrawingHandle {
    getPDF: () => string | null;
}

const Drawing = forwardRef<DrawingHandle, IDrawingProps>(function Drawing({ admin, renderEmpty, width }: IDrawingProps, ref) {

    const { identifier } = useParams();
    //Odpovědi z dotazníku. Kontext inicializován v Form.tsx /models a updatovan, aby neměl defaultní hodnoty nevím kde :(
    const [solution, setData] = useState(useContext(SolutionContext));

    //Z app.tsx se zavolá setPAgeTitle. Proč je to useEffect a ne funkce samotná, to nevím
    useEffect(() => {
        setPageTitle("Drawing")
        const fetchData = async (identifier: string) => {
            console.log('Loading drawing', identifier)
            const result = await fetch(`/api/drawing/${identifier}`)
            if (result.status === 200) {
                const data = await result.json()
                console.log(data)
                setData(data)
            }
        }
        if (identifier !== undefined) {
            fetchData(identifier)
        }
    }, [identifier]);


    // Use useImperativeHandle to expose the drawSomething method
    useImperativeHandle(ref, () => ({
        getPDF
    }));

    //Useref se používá, když nechci při jeho změně rerenderovat celej DOM
    const svgRef = useRef<SVGSVGElement>(null);
    const downloadRef = useRef<HTMLAnchorElement>(null);

    //printedBorder může být typu PrintedBorder anebo typu undefined
    let printedBorder: PrintedBorder | undefined;

    // Define the method to expose to the parent
    const getPDF = () => {
        if (svgRef.current === null) {
            console.log('SVG is null')
            return null
        }
        console.log('Generating PDF...');
        const doc = new PDFDocument({ size: "A4", layout: "landscape" });
        //const stream = doc.pipe(blobStream());
        SVGtoPDF(doc, svgRef.current, 0, 0, { useCSS: true });
        doc.end();
        const data = doc.read();
        const base64 = data.toString("base64");
        return base64;
    };


    fillSolutionsEmpty(solution)

    console.log(solution)


    /*
      Pokud uživatel v dotazniku chce printed frame, solution.printing nebude null
      Jinak jo a printedBorder bude undefined
    */

    if (solution.printing?.withPrinting === true) {

        /*    
        PrintedBorder neslouží jako ostatní komponenty. Repsektive jo, ale nemělo by. Namísto toho, aby se uložil "border", což
        je obdelník s obdelnikovou dírou, tak se uloží jen ta "díra". Width tedy je šířka nepostklé části. PrintedBorder vlastně spíš ukládá unPrintedBorder
        a mimo unPrintedBorder bude celé sklo potisklé
        */
        printedBorder = new PrintedBorder(4, "black", solution);

    }

    const glass = new Glass(0, solution);

    const activeArea = new ActiveArea(3, solution, printedBorder, printedBorder?.shape);

    const sensor = new Sensor(2, solution, printedBorder?.shape ?? activeArea.shape, activeArea);

    const sticker = new Sticker(6, solution, sensor);

    const flexCable = new FlexibleCable(5, solution, sticker);

    const objects: PrintedLogo[] = []
    if (printedBorder && solution.printing?.withLogo) {
        solution.printing?.objects.map((obj, i) => {
            objects.push(new PrintedLogo(i + 7, obj, solution.glass))
        })
    }


    var components

    if (solution.sensor.withSensor === true) {
        components = [
            glass, sensor, activeArea, flexCable, sticker, ...objects
        ];
    } else {
        components = [
            glass, ...objects
        ];
    }
    if (printedBorder)
        components.push(printedBorder);
    components.sort((a, b) => a.info.id - b.info.id);


    const measuredComponents = components.filter(c => c.labels && c.labels.dimensions).reverse();
    const measureOffsets: ISideOffsets[] = [];



    let offsets = { t: -0.0265*width, b: 0.0265*width, l: -0.0265*width, r: 0.0265*width } as ISidePadding;

    for (let c of measuredComponents) {
        measureOffsets.push({ ...offsets });

        if (c.labels.dimensions?.sides.includes(Side.Top)) offsets.t -= 22;
        if (c.labels.dimensions?.sides.includes(Side.Bottom)) offsets.b += 22;
        if (c.labels.dimensions?.sides.includes(Side.Left)) offsets.l -= 22;
        if (c.labels.dimensions?.sides.includes(Side.Right)) offsets.r += 22;

    }






    /*
    Přepočet do formátu papíru A je vždy vyska = delka/sqrt(2)
    */
    const fontSize = 0.009 * width;
    let canvasW = width
    let canvasH = (canvasW / Math.sqrt(2)) //793



    /*
    AFAIK toto ovlivnuje jen uncofirmed nápis
    27.8. -> watermark commented out
    */
    //const fullArea = { width: canvasW, height: canvasH, offsetX: 0, offsetY: 0 } as IArea;

    /*
    Toto ovlivnuje, kam se vykreslí frontview
    Velikost je menší než fullArea (obvisously) a offest posouvá, kde se to zacne vykreslovat
    */
    const drawingFrontArea = { width: 1 * canvasW, height: 0.6 * canvasH, offsetX: 0, offsetY: 20 } as IArea;

    /*
    Ovlivnuje, kde se vykresli side view
    Pokud chci, aby to bylo zarovnany s front view, musí být šířka a offest x stejný, jak drawing frontArea
    Pokud budu tedy měnit width nebo offsetX na drawingFrontArea, musím to samé změnit i na drawingProfileArea
    */
    const drawingProfileArea = { width: drawingFrontArea.width, height: 0.16 * canvasH, offsetX: drawingFrontArea.offsetX, offsetY: drawingFrontArea.height + drawingFrontArea.offsetY } as IArea;
    //const drawingProfileArea = { width: 0.7 * canvasW, height: 0.16 * canvasH, offsetX: 0, offsetY: 0.6 * canvasH };

    /*
    Ovlivnuje, kde budou notes
    siroky to je 30% fullAre a je offsetnuta o 70% fullArea
    */
    const notesArea = { width: 0.3 * canvasW, height: canvasH - (drawingProfileArea.height + drawingProfileArea.offsetY), offsetX: 20, offsetY: drawingFrontArea.height + drawingProfileArea.height } as IArea;


    /*
    Ovlivnuje, kam se vykreslí tabulka dole vpravo
    Obdobně jak area pro notes
    */
    const infoTableArea = { width: 0.65 * canvasW - 20, height: 0.24 * canvasH - 20, offsetX: 0.35 * canvasW, offsetY: drawingFrontArea.height + drawingProfileArea.height } as IArea;

    /*
    Dozjistit, co dělá getDrawingInfo
    Asi vrací jak velky bude front view a kam ho dat
    A getInfo vraci scale pro sideview
    */
    const { scale: scaleFront, drawingBounds, componentBounds, drawingOrientation } = getDrawingInfo(components, drawingFrontArea, { t: fontSize, l: fontSize, r: fontSize, b: fontSize }, offsets);
    const { scale: scaleProfile } = getInfo(scaleFront, drawingProfileArea, { t: 0, l: 0, r: 0, b: 0 });



    /*
    Toto má cosi společnýho s otáčením frontview
    */
    const drawingFrontTransform = undefined

    /*
    Vytvoří jen další BaseComponent pro side view
    nic složitýho
    */

    const glassProfile = new GlassProfile(1, solution, glass, -0.5 * (componentBounds.x + componentBounds._x) - 0.5 * scaleFront.size.invert(offsets.r + offsets.l));


    /*
    vytvoří seznam baseComponentů, co mají radius v shapu, co není nulový nebo undefined
    */
    const radiiComponents = components.filter(c => isRoundedRect(c.shape) && isNonZero(c.shape.radius ?? 0) && !componentWantsNoRaddiRendered(c.shape));
    /*
    portsě list basecomponentu, co chcou mít id tuplik ve vykresu
    */
    const identifiedComponents = components.concat(glassProfile).filter(c => c.labels && c.labels.id);
    /*
    seřadí podle info.id a zvýší všem o jedno
    */
    identifiedComponents.sort((a, b) => a.info.id - b.info.id).forEach((c, i) => c.info.id = i + 1);
    const frontIdentifiedComponents = identifiedComponents.map(c => c);
    /*
    Oddelá z frontIdentifiedComponents glassProfile, zbydou jen komponentny, co skutecne maj byt vykresleny v front view
    */
    frontIdentifiedComponents.splice(identifiedComponents.findIndex(c => c === glassProfile), 1);

    /*
    vytvoreni hlaseni erroru
    */


    const printing = solution.printing;
    const customization = solution.customization;

    const allAdditionals = [
        ...solutionErrors(solutionFields, solution),
        ...glassSensorAdditional(glass, sensor, activeArea, printedBorder, solution.sensor.withSensor),
        ...objectErrorsAdditional(solution.printing?.objects, solution.glass, solution.printing?.frameThickness, solution.printing?.withLogo),
        ...objectNotesAdditional(solution.printing?.objects, solution.printing?.withLogo),
        cutoutsAdditional(customization),
        ...tailErrorsAdditional(solution.sensor.tail, solution.sensor),
        tailAdditional(solution.sensor.tail.isCustom),
        notesAdditional(solution.product.notes),
    ].filter(t => (t != null))

    /*
    Vytvoří se string[] z tech vsech a vyfiltruji se všechny falsey (null, undefined)
    */


    const download = () => {

        if (!svgRef.current) {
            return;
        }


        const wTmp = canvasW
        const hTmp = canvasH
        canvasW = 1122
        canvasH = canvasW / Math.sqrt(2);
        const doc = new PDFDocument({ size: "A4", layout: "landscape" });
        //const doc = Drawing({admin: false, renderEmpty: false, width:1122}, ref)
        const stream = doc.pipe(blobStream());
        SVGtoPDF(doc, svgRef.current, 0, 0, { useCSS: true });
        doc.end();
        canvasW = wTmp
        canvasH = hTmp
        stream.on('finish', () => {
            if (!downloadRef.current) return;

            const blob = stream.toBlob();
            const url = window.URL.createObjectURL(blob);
            downloadRef.current.href = url;
            downloadRef.current.click();
            window.URL.revokeObjectURL(url);
        })
    };

    /*
    Definuje navigation funkce
    */



    if (renderEmpty) return (
        <Box width={canvasW} height={canvasH} style={{
            border: "1px solid #dfe0f2",
            borderRadius: "35px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#3e3259",
            flexDirection: "column"
        }}>
            <Box sx={{
                border: "3px solid #3e3259",
                borderRadius: "9999px",
                marginBottom: "20px",
                padding: "5px",
                height: "20px",
                width: "20px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                fontSize: 24,
                fontWeight: "bold",
            }}>
                i
            </Box>
            <Box>
                fill in all the details and you will see your proposal here
            </Box>
        </Box>
    )
    return <FontContext.Provider value={{ size: fontSize }}>
        

        {/*
        <Box sx={{ p: 2, display: 'flex', columnGap: 1 }}>
            <Button variant="contained" onClick={() => download()}>Download PDF</Button>

            
            <a ref={downloadRef} style={{ display: "none" }} download="technical-drawing.pdf"></a>
        </Box>*/}



        {/* Tady se začíná kreslit */}
        <svg ref={svgRef} width={canvasW} height={canvasH} style={{ border: "1px solid #dfe0f2", borderRadius: "35px" }}>



            {/* Tady se vykreslí celý front view */}
            <g id="drawing-front" transform={drawingFrontTransform}>
                <ScaleContext.Provider value={scaleFront}>
                    <text x={scaleFront.posX(drawingOrientation === Orientation.Landscape ? drawingBounds._x : drawingBounds._y)} y={2 * fontSize} textAnchor="middle" fontSize={fontSize} fill="#000">Front view</text>

                    <g className="components">
                        {components.map((c, i) => c.render(canvasW))}
                    </g>
                    <g className="dimensions">
                        {measuredComponents.map((c, i) => c.labels.dimensions && <Dimensions key={i} measuredShape={c.shape} measuredInfo={c.info} referentialShape={glass.shape}
                            componentBounds={componentBounds} maxTickLength={15} offset={measureOffsets[i]} diff={c.labels.dimensions.diff} sides={c.labels.dimensions.sides} />
                        )}
                    </g>
                    <g className="ids">
                        {frontIdentifiedComponents.map((c, i) => c.labels.id && <Id key={i} shape={c.shape} info={c.info} {...c.labels.id} />)}
                    </g>
                    <g className="radii">
                        {radiiComponents.map((c, i) => <Radius key={i} measuredShape={c.shape} measuredInfo={c.info} arrowLength={50} />)}
                    </g>
                </ScaleContext.Provider>
            </g>

            {/* Tady se vykreslí celý side view */}
            <g id="drawing-profile">
                <text x={scaleProfile.posX(getBounds(glassProfile.shape)._x)} y={drawingProfileArea.offsetY + 2 * fontSize} textAnchor="middle" fontSize={fontSize} fill="#000">Side view</text>
                <ScaleContext.Provider value={scaleProfile}>
                    {/* Vyklreslení glass profilu (jen rectangle) */}
                    {glassProfile.render()}

                    {/* Vyklreslení meřítek glass profilu */}

                    <Dimensions measuredShape={glassProfile.shape} measuredInfo={glassProfile.info} referentialShape={glassProfile.shape} componentBounds={drawingBounds}
                        maxTickLength={15} offset={{ t: 0, b: 0, l: 0, r: -45 }} diff={glassProfile.labels.dimensions?.diff ?? false} sides={glassProfile.labels.dimensions?.sides ?? []} />

                    {/*Vykreslení labelů, tech tuplíku*/}
                    {glassProfile.labels.id && <Id shape={glassProfile.shape} info={glassProfile.info} {...glassProfile.labels.id} />}
                </ScaleContext.Provider>
            </g>


            {/* Tady se vykreslí popis prvků (1. Glass \n2. Touch sensor...) a hlášení errorů a notes*/}
            <FontContext.Provider value={{ size: fontSize * 1.5 }}>
                <g id="descriptions">
                    <Descriptions area={notesArea} components={identifiedComponents} />
                </g>
            </FontContext.Provider>


            {/*Tady se vykreslí tabulka dole a UNCONFIRMED nápis*/}
            <g id="info-table">
                <InfoTable area={infoTableArea} solution={solution} />
            </g>
            {/*<Watermark area={fullArea} solution={solution} />*/}
        </svg>

        <Box>
            {allAdditionals.map((obj: IAdditionalNotes | undefined, i) => {
                if (obj == undefined) return
                return <AdditionalText text={obj.text} type={obj.type} width={canvasW-30}/>
            })}
        </Box>
    </FontContext.Provider>;
}
)

export default Drawing;
import React from 'react';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Page404 from './Page404';
import Form from './Form';
import Drawing from './Drawing';
import { createTheme, ThemeProvider } from '@mui/material';
import Confirm from './Confirm';

/**
 * Sets header of page. "Tab header name", lets say.
 * Technical drawing generator if s is empty, s | Technical drawing generator else.
 * @param s string to set as name of tab header
 */
export function setPageTitle(s: string) {
    const t = "Technical drawing generator";
    document.title = s ? s + " | " + t : t;
}

function App() {
    return <ThemeProvider theme={theme}>
        <BrowserRouter>
            <Routes>
                <Route path="/confirm" element={React.createElement(Confirm)} />
                {/*<Route path="/admin-form" element={React.createElement(Form, { admin: true })} />*/}
                <Route path="/form" element={React.createElement(Form, { admin: false })} />
                <Route path="/admin-drawing/:identifier?" element={React.createElement(Drawing, { admin: true, renderEmpty: false, width: 1920 })} />
                {/*<Route path="/drawing/:identifier?" element={React.createElement(Drawing, { admin: false, renderEmpty: false })} />*/}
                <Route path="/" element={<Navigate to="/form" />} />
                <Route path="*" element={(<Page404 />)} />
            </Routes>
        </BrowserRouter>
    </ThemeProvider>
}

export default App;

const theme = createTheme({
    typography: {
        fontFamily: [
            'Montserrat',
        ].join(','),
        fontWeightRegular: 500
    }
});

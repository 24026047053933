import { AddCircle } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";

export function PlusButton(props: {label: string, onClick: () => void}) : JSX.Element {
    return (
        <IconButton
            sx={{ 
              width: 'fit-content'
            }}
            onClick = {props.onClick}>
                <AddCircle sx={{color:"#250048", marginRight:"5px" }} />
                <Typography color="#250048" fontSize="60%" maxWidth="200px" fontWeight="500">{props.label}</Typography>
        </IconButton>
    )
}
import { getIn } from "formik";
import { IProps } from "../MuiField";
import { getOnChange } from "../../models/Form";
import { Grid, TextField, Typography } from "@mui/material";

export function StyledNotesMuiField(props: IProps) : JSX.Element {
  const disabled = props.disabled ?? false;
  const fullWidth = props.fullWidth ?? true;

  // const { t } = useTranslation();
  const form = props.form;
  const field = props.field;
  
  const errors = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  const onChange = getOnChange(props);
  
  return (                    
      <TextField
          fullWidth={fullWidth}
          id={field.name}
          name={field.name}
          type={props.type}
          disabled={disabled}
          value={field.value}
          onChange={onChange}
          onBlur={field.onBlur}
          defaultChecked={field.checked}
          error={touched && Boolean(errors)}
          multiline
          rows="4"
          inputProps={props.inputProps}
          InputProps={props.InputProps}
          placeholder={props.helperText}
  
          sx={{
              color: "red",
              backgroundColor: "white",
              border: "1px solid #dfe0f2",
              borderRadius: "14px",
              '& .MuiOutlinedInput-notchedOutline': {
                  border: "0px solid black",
              },
              '& .MuiOutlinedInput-root': {
                  color: "#250048",
                  padding:"0px",
                  borderRadius: "14px",
                  '&.Mui-focused fieldset': {
                  borderColor: '#1a0549',
                  },
              },
              '& .MuiOutlinedInput-input': {
                  padding:"5px 10px 5px 10px",
              },
          }}
      >
          {props.children}
      </TextField>
  )
}
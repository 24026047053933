import { Padding } from "@mui/icons-material"
import { Box, Button } from "@mui/material"
import zIndex from "@mui/material/styles/zIndex"
import { content, height } from "pdfkit/js/page"

interface INextProps{
  text : "next step" | "Submit" | "Create another drawing",
  submit?: boolean,
  onClick?: () => void
}

export function NextButton(props : INextProps) : JSX.Element {


  const styleMain = {
    justifySelf: "right",
    backgroundColor: "white",
    borderRadius: "999px",
    padding:"0px",
    '&: hover': {
      backgroundColor: "#250048",
    }
  }
  const styleTextAndArrow = {
    height:"45px",
    color: "white",
    backgroundColor: "#250048",
    borderRadius: "999px",
    '&: hover': {
      backgroundColor: "#250048",
    }
  }
  const styleText = {
    ...styleTextAndArrow,
    transform: 'translateX(3px)',
    zIndex:"1",
    padding: "0 20px 0 20px",
    textTransform: "none",
  }
  const styleArrow = {
    ...styleTextAndArrow,
    transform: 'translateX(-3px)',
    padding:"0px",
    minWidth:"45px",
    width: "45px",
  }

  if(props.submit) return (

    <Button
    sx={styleMain}
      type="submit">
      <Box sx={styleText} display="flex" alignItems="center">{props.text}</Box>
      <Box sx={styleArrow} display="flex" alignItems="center" justifyContent="center"><svg fill="#FFF" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2"><g data-name="arrow-forward"><rect width="24" height="24" transform="rotate(-90 12 12)" opacity="0"/><path d="M5 13h11.86l-3.63 4.36a1 1 0 0 0 1.54 1.28l5-6a1.19 1.19 0 0 0 .09-.15c0-.05.05-.08.07-.13A1 1 0 0 0 20 12a1 1 0 0 0-.07-.36c0-.05-.05-.08-.07-.13a1.19 1.19 0 0 0-.09-.15l-5-6A1 1 0 0 0 14 5a1 1 0 0 0-.64.23 1 1 0 0 0-.13 1.41L16.86 11H5a1 1 0 0 0 0 2z"/></g></g></svg></Box>
    </Button>

  )
  else return (
    <Button
      sx={styleMain}
      onClick={props.onClick}>
      <Box sx={styleText} display="flex" alignItems="center">{props.text}</Box>
      <Box sx={styleArrow} display="flex" alignItems="center" justifyContent="center"><svg fill="#FFF" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2"><g data-name="arrow-forward"><rect width="24" height="24" transform="rotate(-90 12 12)" opacity="0"/><path d="M5 13h11.86l-3.63 4.36a1 1 0 0 0 1.54 1.28l5-6a1.19 1.19 0 0 0 .09-.15c0-.05.05-.08.07-.13A1 1 0 0 0 20 12a1 1 0 0 0-.07-.36c0-.05-.05-.08-.07-.13a1.19 1.19 0 0 0-.09-.15l-5-6A1 1 0 0 0 14 5a1 1 0 0 0-.64.23 1 1 0 0 0-.13 1.41L16.86 11H5a1 1 0 0 0 0 2z"/></g></g></svg></Box>
    </Button>
  )
}
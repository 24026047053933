import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { setPageTitle } from "./App";
import { Form as FormikForm, Formik, FormikProps, FieldArray, Field, FieldProps, FieldArrayRenderProps } from "formik";
import { SolutionContext, IsSentContext, applyConfig, defaultFormValues, isDefault, isDefaultDisplay } from "./models/Form";
import { Box, Button, FormGroup, Grid, IconButton, Link, List, Paper, Typography } from "@mui/material";
import { SolutionField, SolutionConfigContext } from "./form/SolutionField";
import { BinaryQuestion } from "./form/Question";
import { useNavigate } from "react-router-dom";
import MuiField from "./form/MuiField";
import { TailAttachment, opposite, standardSensors, allAttachments, getCurrentAttachments, findStandardSensor, getAlternativeAttachments, isRotated } from "./models/Sensor";
import { areAllCornersSame, hasAntiGlare } from "./models/Customization";
import { IPrintedObject, defaultLogo } from "./models/Printing";
import { Orientation } from "./models/Common";
import { ProductType } from "./models/Product";
import { thicknessToString } from "./models/Glass";
import { ISolution, getSolutionConfig } from "./models/Solution";
import { changeSurfaceRule, changeSensorTypeRule, inferGlassOrientationRule, inferStandardSensorRule, solutionRules } from "./models/SolutionRules";
import { CheckboxField } from "./form/Checkbox";
import FileDialog from "./form/Dropzone";
import Drawing, { DrawingHandle } from "./Drawing";
import { HeadTab } from "./form/HeadTab";
import { NextButton } from "./form/NextButton";
import { PreviousButton } from "./form/PreviousButton";
import { FormPageHeader } from "./form/FormPageHeader";
import { CornerRadiusField } from "./form/CornerRadius";
import { BorderSpacing } from "./form/BorderSpacing";
import { PairedInput } from "./form/PairedInput";
import { DeleteOutline, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { StyledMuiFieldLabel } from "./form/muiFields/MuiFIeldLabel";
import { PlusButton } from "./form/PlusButton";
import { Separator } from "./form/Separator";
import errorFields from "./form/ErrorFields";
import useWindowSize from "./windowSize";
import { height, width } from "pdfkit/js/page";

interface IFormPage {
    id: number,
    title: string,
    attribute: keyof ISolution,
    adminOnly: boolean,
}
const allPages = [
    { id: 0, title: "Product", attribute: "product", adminOnly: false },
    { id: 1, title: "Glass", attribute: "glass", adminOnly: false },
    { id: 2, title: "Glass Customization", attribute: "customization", adminOnly: false },
    { id: 3, title: "Glass Printing", attribute: "printing", adminOnly: false },
    { id: 4, title: "Sensor", attribute: "sensor", adminOnly: false },
    { id: 5, title: "Display", attribute: "display", adminOnly: false },
    { id: 6, title: "Additional Notes", attribute: "product", adminOnly: false },
    { id: 7, title: "Customer", attribute: "customer", adminOnly: false },
    { id: 8, title: "Status", attribute: "status", adminOnly: true },
] as IFormPage[];

interface IFormProps {
    admin: boolean,
}

const scrollbarSX = {"&::-webkit-scrollbar, & *::-webkit-scrollbar":{
                            backgroundColor: "#0000",
                            width:"5px"
                        },
                        "&::-webkit-scrollbar-track-piece": {
                            backgroundColor:"#0000",
                            border:"1px solid #dfe0f2",
                            borderRight:"0px",
                            borderRadius:8,
                        },
                        "&::-webkit-scrollbar-track-piece:end " :{
                            marginBottom:"25px",
                        },
                        "&::-webkit-scrollbar-track-piece:start " :{
                            marginTop:"25px",
                        },
                        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                            borderRadius: 8,
                            backgroundColor: "#1a0549",
                            minHeight: 24,
                            border: "0px solid white",
                            height: '10px',
                        },
                        "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                            backgroundColor: "#1a0549",
                        },
                        "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                            backgroundColor: "#1a0549",
                        },
                        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: "#1a0549",
                        },}

export default function Form({ admin }: IFormProps) {
    useEffect(() => setPageTitle('Form'), []);
    const drawingRef = useRef<DrawingHandle>(null); // Create a ref for Drawing component
    const groupSx = { 
        display: "flex", 
        flexDirection: "column", 
        '> *': {
            margin: "0 0 20px 0",
            '&:last-child':{
                margin: "0 0 0 0",
            }
        },
        mt: "2em"
    };
    const primaryGroupSx = { ...groupSx, "& > .MuiFormLabel-root": { fontSize: "150%" } };

    let ref = useRef<FormikProps<ISolution> | null>(null);
    const solutionContext = useContext(SolutionContext);
    const isSentContext = useContext(IsSentContext)
    const freshForm = isDefault(solutionContext);

    const [key, setKey] = useState(0);

    // define utility variables that are not part of the ISolution
    // '' is to clear the controlled component
    const [withPrinting, setWithPrinting] = useState<boolean | ''>(freshForm ? false : solutionContext.printing?.withPrinting === true);
    const [withLogo, setWithLogo] = useState<boolean | ''>(freshForm ? false : solutionContext.printing?.withLogo === true);
    const [withDisplay, setWithDisplay] = useState<boolean | ''>(freshForm ? '' : !isDefaultDisplay(solutionContext));
    const [allCornersSame, setAllCornersSame] = useState<boolean>(freshForm ? false : areAllCornersSame(solutionContext.customization?.corners));
    const [withSensor, setWithSensor] = useState<boolean>(freshForm ? false : solutionContext.sensor.withSensor === true);
    const [allowedTailAttachments, setAllowedTailAttachments] = useState<TailAttachment[]>(solutionContext.sensor.isCustom ? allAttachments : [solutionContext.sensor.tail.attachment, opposite(solutionContext.sensor.tail.attachment)]);
    const [formPage, setFormPage] = useState<number>(0); // index, not id
    const [maxVisitedFormPage, setMaxVisitedFormPage] = useState<number>(0); // index, not id
    const [technicalDataReady, setTechnicalDataReady] = useState<boolean>(false);

    // duplicate selected form data in state for correct rerendering and effects
    const [standardSensor, setStandardSensor] = useState<boolean>(freshForm ? true : !solutionContext.sensor.isCustom);
    const [glassOrientation, setGlassOrientation] = useState<Orientation>(solutionContext.glass.orientation);
    const [productType, setProductType] = useState<ProductType>(solutionContext.product.type);
    const [antiGlare, setAntiGlare] = useState<boolean>(solutionContext.customization ? hasAntiGlare(solutionContext.customization.surface) : false);
    const [lamination, setLamination] = useState<boolean>(solutionContext.customization?.lamination ?? false);

    const { fixedValues, constraints } = useMemo(() => getSolutionConfig(productType), [productType]);

    const fileArr = useRef<(File | null)[]>([])
    if (solutionContext.printing) {
        const fileArrDesiredLen = solutionContext.printing.objects.length
        if (fileArr.current.length < fileArrDesiredLen) {
            const toPush = fileArrDesiredLen - fileArr.current.length



            for (let i = 0; i < toPush; i++) {
                fileArr.current.push(null)
            }


        }

        solutionContext.printing.objects.forEach((obj, i) => {
            if (obj.file !== null || obj.file !== undefined) {
                if (fileArr.current[i] === null) {
                    fileArr.current[i] = obj.file
                }
            }
        })
    }

    useEffect(() => {
        if (!ref.current) return;
        applyConfig(ref.current, { fixedValues, constraints });
        setLamination(fixedValues.customization?.lamination ?? ref.current.values.customization?.lamination ?? false);
    }, [productType, fixedValues, constraints]);

    const pages = useMemo(() => allPages.filter(page => fixedValues[page.attribute] !== null && (admin || !page.adminOnly)), [admin, fixedValues]);

    // add missing data sections so that form can use them
    if (solutionContext.printing === null)
        solutionContext.printing = { ...defaultFormValues.printing! };
    if (solutionContext.display === null)
        solutionContext.display = { ...defaultFormValues.display! };
    if (solutionContext.customization && solutionContext.customization.antiGlareType === null)
        solutionContext.customization.antiGlareType = defaultFormValues.customization!.antiGlareType;

    const navigate = useNavigate();

    const sendToDB = useCallback(() => {
        if (drawingRef.current) {
            const base64PDFData = drawingRef.current.getPDF();
            const result = fetch('/api/drawing', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...solutionContext, pdf: base64PDFData })
            })
            console.log('Drawing sent to server.');
        }
        else {
            console.log('Drawing not available')
        }
    }, [solutionContext]);

    const changePage = useCallback((currentPage: number, backwards: boolean) => {
        
        for(let key in errorFields) {
            if(errorFields[key] == true) return false
        }
        const newPage = backwards ? currentPage - 1 : currentPage + 1;
        if (newPage >= 0 && newPage < pages.length) {
            setFormPage(newPage);
            setMaxVisitedFormPage(Math.max(newPage, maxVisitedFormPage));
        }
    }, [pages, maxVisitedFormPage]);

    const onTechnicalDataFilled = useCallback(() => {
        if (ref.current !== null) {
            const values = ref.current.values

            if (!withPrinting) {
                if (values.printing) {
                    values.printing.withPrinting = false;
                }
            } else {
                if (values.printing) {
                    values.printing.withPrinting = true;
                }
            }

            if (!withLogo) {
                if (values.printing) {
                    values.printing.withLogo = false;
                }
            } else {
                if (values.printing) {
                    values.printing.withLogo = true;
                }
            }

            values.sensor.withSensor = withSensor

            if (values.printing) {
                values.printing.objects.forEach((objFiltred, i) => {
                    if (objFiltred.file) {
                        objFiltred.file = fileArr.current[i]
                    }
                })
            }
            if (!withDisplay)
                values.display = null;
            if (values.customization && !hasAntiGlare(values.customization.surface))
                values.customization.antiGlareType = null;

            if (allCornersSame && values.customization)
                values.customization.corners.tr = values.customization.corners.bl = values.customization.corners.br = values.customization.corners.tl;

            Object.assign(solutionContext, values);
            console.log('Filled values:', solutionContext)
            setTechnicalDataReady(true)
            changePage(formPage, false)
        }
    }, [allCornersSame, changePage, formPage, solutionContext, withDisplay, withLogo, withPrinting]);

    const onSubmit = useCallback((values: ISolution) => {
        Object.assign(solutionContext, values);
        isSentContext.sent = true
        setKey(key + 1) // enforces drawing component rerender

        // FIXME: Drawing component is not rerendered immediately, so this is a quick and dirty fix
        setTimeout(() => {
            sendToDB()
            console.log("Technical drawing submitted.")
            isSentContext.sent = false
            navigate('/confirm')
        }, 1000)

    }, [solutionContext, key, sendToDB, navigate]);

    const canChangePage = useCallback((currentPage: number, backwards: boolean) => {
        const newPage = backwards ? currentPage - 1 : currentPage + 1;
        return newPage >= 0 && newPage < pages.length;
    }, [pages]);

    // Add rules that update duplicated and utility variables
    // The rules will be executed _after_ the main rules
    // to have access to the latest solution values
    useEffect(() => {
        solutionRules.push(
            {
                controlFields: [
                    ...changeSurfaceRule.controlFields,
                    'customization.surface',
                ],
                controlledFields: [],
                apply: (solution: ISolution) => {
                    if (solution.customization)
                        setAntiGlare(hasAntiGlare(solution.customization.surface));
                }
            },
            {
                controlFields: inferGlassOrientationRule.controlFields,
                controlledFields: [],
                apply: (solution: ISolution) => {
                    setGlassOrientation(solution.glass.orientation);
                }
            },
            {
                controlFields: changeSensorTypeRule.controlFields,
                controlledFields: [],
                apply: (solution: ISolution) => {
                    const isStandard = !solution.sensor.isCustom;
                    const sensor = isStandard ? standardSensors[0] : solution.sensor;
                    setStandardSensor(isStandard);
                    setAllowedTailAttachments(isStandard ? getCurrentAttachments(sensor) : allAttachments);
                }
            },
            {
                controlFields: inferStandardSensorRule.controlFields,
                controlledFields: [],
                apply: (solution: ISolution) => {
                    const sensor = findStandardSensor(solution.sensor.diagonal);
                    if (solution.sensor.isCustom || !sensor)
                        return;
                    setAllowedTailAttachments(isRotated(solution.sensor) ? getAlternativeAttachments(sensor) : getCurrentAttachments(sensor));
                }
            },
        );
    }, []);


    const menuButtonSx = useCallback((i: number) => (formPage === i), [formPage]);























    function ObjectForm(props: { oi: number, objectArrayHelpers: FieldArrayRenderProps }): JSX.Element {
        const [isHidden, Hide] = useState(ref.current?.values.printing?.objects[props.oi]?.formHidden)



        return (
            <FormGroup key={props.oi}>
                <Box

                    sx={{
                        '> *': {
                            padding: "5px 0 5px 0"
                        },
                        border: "1px solid #dfe0f2",
                        borderRadius: "14px",
                        padding: "10px 25px 10px 25px",
                        backgroundColor: "white"
                    }}>

                    <Box display='flex' justifyContent='space-between'>
                        <Field name={`printing.objects.${props.oi}.title`} type="rawText" component={MuiField} helperText="title" value={5} />

                        <Box display="flex" justifyContent="center" alignItems="center">
                            <IconButton onClick={() => props.objectArrayHelpers.remove(props.oi)}><DeleteOutline sx={{ color: "#250048" }} /></IconButton>
                            <Box sx={{ borderRight: "1px solid #EEF2F6", margin: "0 10px 0 10px", width: "1px", height: "25px" }} />
                            <Field component={(fieldProps: FieldProps<number, ISolution>) =>
                                <IconButton
                                    sx={{ ':hover': { backgroundColor: "white" }, '& :hover': { backgroundColor: "#250048", color: "white" } }}
                                    onClick={() => {
                                        Hide(!isHidden)
                                        fieldProps.form.setFieldValue(`printing.objects.${props.oi}.formHidden`, !isHidden)
                                    }}>
                                    {!isHidden && <KeyboardArrowUp sx={{ color: "#250048", backgroundColor: "#eef2f6", borderRadius: "999px", padding: "3px" }} />}
                                    {isHidden && <KeyboardArrowDown sx={{ color: "#250048", backgroundColor: "#eef2f6", borderRadius: "999px", padding: "3px" }} />}
                                </IconButton>
                            } />
                        </Box>
                    </Box>



                    {!isHidden && <>
                        <Separator />
                        <Field xsmd={[6, 6]} name={`printing.objects.${props.oi}.wImg`} label="Draw with image (.svg)" type="checkbox" component={CheckboxField} />
                        {(ref.current?.values.printing ? ref.current?.values.printing.objects[props.oi]?.wImg : false) && <>
                            <Field xsmd={[6, 6]} name={`printing.objects.${props.oi}.height`} label="Height (mm)" type="number" component={MuiField} alignRight />
                            <Field xsmd={[6, 6]} name={`printing.objects.${props.oi}.file`} solution={solutionContext} label="" type="file" component={FileDialog} fileArr={fileArr} indexOfObj={props.oi} />
                        </>}
                        {!(ref.current?.values.printing ? ref.current?.values.printing.objects[props.oi]?.wImg : false) && <>
                            <Field xsmd={[6, 6]} name={`printing.objects.${props.oi}.isCircle`} label="Logo is circle shaped" type="checkbox" component={CheckboxField} />
                            {(ref.current?.values.printing ? ref.current?.values.printing.objects[props.oi]?.isCircle : false) && <>
                                <Field xsmd={[6, 6]} name={`printing.objects.${props.oi}.radius`} label="radius" type="number" component={MuiField} alignRight />
                            </>}
                            {!(ref.current?.values.printing ? ref.current?.values.printing.objects[props.oi]?.isCircle : false) && <>
                                <Field xsmd={[6, 6]} name={`printing.objects.${props.oi}.width`} label="Width (mm)" type="number" component={MuiField} alignRight />
                                <Field xsmd={[6, 6]} name={`printing.objects.${props.oi}.height`} label="Height (mm)" type="number" component={MuiField} alignRight />
                            </>}
                        </>}





                        <Field xsmd={[6, 6]} name={`printing.objects.${props.oi}.positioning.centralOffsetX`} label="X offset" type="number" component={MuiField} alignRight />
                        <Field xsmd={[6, 6]} name={`printing.objects.${props.oi}.positioning.centralOffsetY`} label="Y offset" type="number" component={MuiField} alignRight />

                        <Field xsmd={[6, 6]} name={`printing.objects.${props.oi}.bottomLogo`} label="Print logo at the bottom" type="checkbox" component={CheckboxField} />

                        {!(ref.current?.values.printing ? ref.current?.values.printing.objects[props.oi]?.wImg : false) && <>
                            <Box>
                                <Field xsmd={[6, 6]} name={`printing.objects.${props.oi}.isHole`} label="Object is a hole" type="checkbox" component={CheckboxField} />
                                <Field xsmd={[6, 6]} name={`printing.objects.${props.oi}.isTransparent`} label="Object is transparent" type="checkbox" component={CheckboxField} />
                            </Box>
                        </>}

                        <Grid container>
                            <Grid xs={12} md={6}>
                                <StyledMuiFieldLabel label="Color (RAL)" paired={false} />
                            </Grid>

                            <Grid xs={12} md={6}>
                                <FieldArray name={`printing.objects.${props.oi}.colors`} render={colorArrayHelpers =>
                                    <>
                                        {colorArrayHelpers.form.values.printing.objects[props.oi].colors.map((_: string, ci: number) =>
                                            <Box key={ci} display='flex' alignItems='center'>
                                                <Field name={`printing.objects.${props.oi}.colors.${ci}`} type="rawText" component={MuiField} helperText="color" />
                                                <IconButton onClick={() => colorArrayHelpers.remove(ci)}><DeleteOutline sx={{ border: "1px solid #dfe0f2", color: "#250048", padding: "4px", borderRadius: "14px" }} /></IconButton>
                                            </Box>
                                        )}
                                        <PlusButton label="Add color" onClick={() => colorArrayHelpers.push('')} />
                                    </>
                                } />
                            </Grid>
                        </Grid>

                    </>}
                </Box>
            </FormGroup>
        )
    }











    const size : {width: number, height: number } = useWindowSize()
    const direction      = size.width < 1100 ? "column"       : "row"
    const formWidth      = size.width < 1100 ? size.width-140 : (size.width/3)
    let drawingWidth     = size.width < 1100 ? size.width-140 : size.width-(150+formWidth)
    if(isSentContext.sent) {
        drawingWidth = 1122
    }
    //const formHeight     = size.width < 1100 ? 900 : ((drawingWidth > 900) ? 900 : drawingWidth) / Math.sqrt(2)
    const formHeight     = size.width < 1100 ? 900 : (drawingWidth) / Math.sqrt(2)
    const inFormHeight   = formHeight - 200
    const formWidthSX    = {width:`${formWidth}px`}
    const formHeightSX   = size.width < 1100 ? {maxHeight:`${formHeight}px`} : {height:`${formHeight}px`}
    const inFormHeightSX = size.width < 1100 ? {maxHeight:`${inFormHeight}px`} : {height: `${inFormHeight}px`}
    
    return (
        <div>
            <Box sx={{ display: "flex", flexWrap: "wrap", marginLeft: "50px", padding: 2 }}>

                {pages.map((page, i) => <div key={i} >
                    <HeadTab disabled={freshForm && i > maxVisitedFormPage} index={i}
                        isActive={menuButtonSx(i)} onClick={() => {
                            for(let key in errorFields) {
                                if(errorFields[key] == true) return
                            }
                            setFormPage(i)
                        }} text={page.title} />
                </div>)}

                {/*!freshForm && <Button variant="text" type="submit" sx={menuButtonSx(pages.length)}
            disabled={freshForm && maxVisitedFormPage !== pages.length}>Drawing</Button>*/}
            </Box>
            <Grid container direction={direction}>
                <Grid item>
                    <Box sx={{
                        ...formWidthSX,
                        ...formHeightSX,
                        border: "1px solid #dfe0f2",
                        marginBlockEnd: "30px",
                        marginInlineStart: "70px",
                        marginInlineEnd: "50px",
                        borderRadius: "35px",
                        backgroundColor: "#f7fbff",
                        
                    }}>
                        
                        <SolutionConfigContext.Provider value={{ fixedValues, constraints }}>
                            <Formik innerRef={newRef => (ref.current = newRef) && setProductType(newRef.values.product.type)}
                                initialValues={solutionContext} enableReinitialize={true} onSubmit={onSubmit}>
                                <FormikForm>


                                    <Box sx={{ margin: "3em auto", padding: 0 }}>
                                        
                                        <Box sx={{paddingLeft: "40px"}}> <FormPageHeader title={pages[formPage].title} /> </Box>
                                        <Box sx={{
                                            backgroundColor:"#0000",
                                            overflow:"auto",
                                            padding:"0 40px 0 40px",
                                            //width: "calc(min(100%, 500px) - 32px)", 
                                            ...scrollbarSX,
                                            ...inFormHeightSX,
                                        }}>
                                            {pages[formPage].id === 0 && <FormGroup sx={primaryGroupSx}>

                                                <SolutionField name="product.type" /> <Separator />
                                                <SolutionField name="product.usage" /> <Separator />
                                                <SolutionField name="product.pieces" /> <Separator />
                                                <SolutionField name="product.title" />
                                            </FormGroup>}

                                            {pages[formPage].id === 1 && <FormGroup sx={primaryGroupSx}>

                                                <SolutionField name="glass.width" /> <Separator />
                                                <SolutionField name="glass.height" /> <Separator />
                                                <SolutionField name="glass.thickness" valueToString={ref.current ? (value) => thicknessToString(ref.current!.values.glass, value) : undefined} />
                                                
                                            </FormGroup>}

                                            {pages[formPage].id === 2 && <FormGroup sx={primaryGroupSx}>
                                                

                                                <CornerRadiusField allCornersSame={allCornersSame} onCheck={(_, val) => setAllCornersSame(!val)} /> <Separator />

                                                <SolutionField name="customization.cutouts" /> <Separator />

                                                <SolutionField name="customization.surface" />

                                                <PairedInput bigLabel="Other settings" elements={[
                                                    <SolutionField name="customization.tempering" />,
                                                    <SolutionField name="customization.lamination" />,
                                                    <SolutionField name="customization.overheatingProtection" disabled={!lamination} />
                                                ]} />
                                            </FormGroup>}

                                            {pages[formPage].id === 3 && <FormGroup sx={primaryGroupSx}>
                                                

                                                <BinaryQuestion value={withPrinting}
                                                    text="Should the glass have a printed frame?"
                                                    labels={["Yes", "No"]}
                                                    onClicks={[() => setWithPrinting(true), () => { setWithPrinting(false); setWithLogo(false) }]} />

                                                {withPrinting && <>
                                                    <Separator />
                                                    <BorderSpacing label="Frame Thickness (mm)" topSolutionFiledName="printing.frameThickness.t"
                                                        leftSolutionFiledName="printing.frameThickness.l" rightSolutionFiledName="printing.frameThickness.r"
                                                        bottomSolutionFiledName="printing.frameThickness.b" /> <Separator />

                                                    <BinaryQuestion value={withLogo}
                                                        text="Should we print any logo, pictogram or hole on it?"
                                                        labels={["Yes", "No"]}
                                                        onClicks={[() => setWithLogo(true), () => setWithLogo(false)]} />
                                                </>}

                                                {withLogo && <>
                                                    <FieldArray name="printing.objects" render={objectArrayHelpers =>
                                                        <>
                                                            {objectArrayHelpers.form.values.printing.objects.map((_: IPrintedObject, oi: number) =>

                                                                <ObjectForm {...{ oi, objectArrayHelpers }} key={oi} />
                                                            )}
                                                            <PlusButton onClick={() => {
                                                                    const i = objectArrayHelpers.form.values.printing.objects.length
                                                                    objectArrayHelpers.push({ ...defaultLogo, title: `Object ${i + 1}` })
                                                                }}
                                                                label="Add object" />

                                                        </>
                                                    } />
                                                </>}
                                            </FormGroup>}

                                            {pages[formPage].id === 4 && <FormGroup sx={primaryGroupSx}>
                                                

                                                <Field component={(fieldProps: FieldProps<number, ISolution>) =>

                                                    <BinaryQuestion value={withSensor}
                                                        text="Do you want to use sensor?"
                                                        labels={["Yes", "No"]}
                                                        onClicks={[
                                                            () => {
                                                                setWithSensor(true);
                                                            },
                                                            () => {
                                                                setWithSensor(false);
                                                            }
                                                        ]}
                                                    />
                                                } />


                                                {withSensor && <>

                                                    <Separator />
                                                    <SolutionField name="sensor.passiveArea.diagonal" />

                                                    <PairedInput bigLabel="Active area (mm)" elements={[
                                                        <SolutionField name="sensor.activeArea.width" disabled={false} />,
                                                        <SolutionField name="sensor.activeArea.height" disabled={false} />
                                                    ]} /> <Separator />

                                                    <BorderSpacing label="Passive area (mm)" topSolutionFiledName="sensor.passiveArea.top"
                                                        leftSolutionFiledName="sensor.passiveArea.left" rightSolutionFiledName="sensor.passiveArea.right"
                                                        bottomSolutionFiledName="sensor.passiveArea.bottom" />


                                                    <PairedInput bigLabel="Tail (mm)" elements={[
                                                        <SolutionField name="sensor.tail.attachment" />,
                                                        <SolutionField name="sensor.tail.centralOffset" />,
                                                        <SolutionField name="sensor.tail.width" />,
                                                        <SolutionField name="sensor.tail.length" />
                                                    ]} />


                                                    <PairedInput bigLabel="Flexible calble (mm)" elements={[
                                                        <SolutionField name="sensor.cable.height" />
                                                    ]} />

                                                </>}

                                            </FormGroup>}

                                            {pages[formPage].id === 5 && <FormGroup sx={primaryGroupSx}>

                                                <BinaryQuestion value={withDisplay}
                                                    text="Do you already have a display?"
                                                    options={[false, true]}
                                                    labels={["Yes, I will use my own", "No, include one"]}
                                                    onClicks={[() => setWithDisplay(false), () => setWithDisplay(true)]} />

                                                {withDisplay === false && <Typography color="">
                                                    Please send us a technical drawing of your display to <Link href="mailto:sales@fortesinteractive.com">sales@fortesinteractive.com</Link>.
                                                </Typography>}
                                                {withDisplay && <>
                                                    <SolutionField name="display.luminance" />
                                                    <SolutionField name="display.aspectRatio" />
                                                    <SolutionField name="display.connection" />
                                                </>}
                                            </FormGroup>}

                                            {pages[formPage].id === 6 && <FormGroup sx={primaryGroupSx}>

                                                <Typography margin="0 0 17px 0">
                                                    Feel free to add anything else or specify something in more detail.
                                                </Typography>

                                                <SolutionField name="product.notes" />
                                            </FormGroup>}

                                            {pages[formPage].id === 7 && <FormGroup sx={primaryGroupSx}>

                                                <Typography margin="0 0 17px 0">
                                                    Please leave us your contact info so we can process your request more easily.
                                                </Typography>

                                                <SolutionField name="customer.company" /> <Separator />
                                                <SolutionField name="customer.creator" /> <Separator />
                                                <SolutionField name="customer.email" />
                                            </FormGroup>}

                                            {pages[formPage].id === 8 && <FormGroup sx={primaryGroupSx}>

                                                <SolutionField name="status.isApproved" />
                                                <SolutionField name="status.fortesApprover" />
                                                <SolutionField name="status.customerApprover" />
                                            </FormGroup>}

                                        </Box>
                                        
                                        <Box sx={{ mt: "2em", display: "grid", gridTemplateColumns: "1fr 1fr", padding:"0 40px 0 40px" }}>
                                            {(!canChangePage(formPage, true)) && <Button sx={{ visibility: "hidden" }} />}
                                            {(canChangePage(formPage, true)) && <PreviousButton onClick={() => changePage(formPage, true)} />}

                                            {(canChangePage(formPage, false) && (pages[formPage].id !== 6)) && <NextButton text="next step" onClick={() => changePage(formPage, false)} />}

                                            {(pages[formPage].id === 6) && <NextButton onClick={() => onTechnicalDataFilled()} text="next step" />}

                                            {!canChangePage(formPage, false) && <NextButton onClick={() => {}} submit={true} text="Submit" />}
                                        </Box>
                                    </Box>
                                </FormikForm>
                            </Formik>
                        </SolutionConfigContext.Provider>

                    </Box>
                </Grid>
                <Grid item>
                        {size.width > 1100 && <Drawing key={key} ref={drawingRef} admin={admin} renderEmpty={!technicalDataReady} width={drawingWidth}></Drawing> }
                        {size.width <= 1100 && <Box sx={{ marginInlineStart: "70px" }}><Drawing key={key} ref={drawingRef} admin={admin} renderEmpty={!technicalDataReady} width={drawingWidth}/></Box> }
                        
                    
                </Grid>
            </Grid>
        </div>
    );
}



import { Box, Button } from "@mui/material"

interface IHeadTabProps {
  isActive : boolean
  text: string
  index: number
  onClick: () => void
  disabled: boolean
}

interface IHeadTabNumber {
  index: number
  disabled: boolean
  active: boolean
}

export function HeadTab(props: IHeadTabProps) : JSX.Element {
  
  
  return <div>
    <Button
      onClick={props.onClick}
      disabled={props.disabled}
      key={props.index}
      sx={{
        color: props.disabled === true ? "#bbb4c9" : props.isActive === true ? "white" : "#1a0549",
        backgroundColor: props.isActive === true ? "#1a0549" : "white",
        margin:"2px",
        borderRadius: "999px",
        ':hover': {
          backgroundColor: "#1a0549",
          color:"white"
        }
      }}
    >
      <HeadTabNumber index= {props.index + 1} disabled={props.disabled} active={props.isActive}/>
      <Box sx={{ margin: "0 5px 0 5px", textTransform: "none" }}>{props.text}</Box>
    </Button>
  </div>
}

function HeadTabNumber(props: IHeadTabNumber) : JSX.Element {

  
  return <Box sx={{
    backgroundColor: props.disabled === true ? "#edeef5" : props.active === true ? "white" : "#dfe0f2",
    borderRadius: "999px",
    height:"25px",
    width:"25px",
    padding: "0px",
    color: props.disabled === true ? "#bbb4c9" : "#1a0549",

  }}>
    {props.index}
  </Box>

}